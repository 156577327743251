.main {
  text-align: center;
}

.header {
  font-size: 32px;
  margin: -30px 20px 20px 20px;
  color: var(--global-color-primary-steel-teal);
  font-family: var(--global-font-subtitle);
  text-align: center;
}

.btn {
  display: block;
  margin: 40px auto;
}

.homepage {
  display: block;
  margin: 40px;
  color: var(--global-color-grayscale-sonic-silver);
}
